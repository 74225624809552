html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.App {
  height: 100%;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

.ml-4 {
  margin-left: 4px;
}
