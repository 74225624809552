.FileWrapper {
  .icon-label {
    position: relative;
  }

  .existing-icon-wrapper {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
