@import "./fonts/Opensans/index";
@import "./scss/spaces";


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,
                 "Segoe UI", "Roboto", "Oxygen",
                 "Ubuntu", "Cantarell", "Fira Sans",
                 "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco,
                 Consolas, "Courier New", monospace;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-normal {
    font-weight: normal !important;
}

.text-center {
    text-align: center;
}
