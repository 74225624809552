.DialogDelete {
  padding: 20px;

  h4 {
    margin: 0;
    font-weight: lighter;
  }

  .you-cannot {
    font-weight: bold;
  }

  .delete-button {
    border-color: rgba(235, 64, 52, .5);
    color: rgb(235, 64, 52);
    margin-left: 8px;

    &:hover {
      border-color: rgb(235, 64, 52);
      background: rgba(235, 64, 52, .03);
    }
  }
}
