.pagination {
    width: auto;
    border-radius: 16px;
    background: rgba(255, 255, 255, .5);

    &__text {
        padding-left: 5px;
        padding-right: 5px;
    }

    &__button {
        padding: 6px;
    }

    &--medium &__button {
        padding: 6px;
    }
}