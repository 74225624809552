.dashboard-layout {
    &__header {
        flex-basis: 81px;
        border-bottom: 1px solid #a2a2a2;
        box-shadow: none !important;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    &__sidebar {
        width: 250px;
        flex-basis: 250px;
        padding-top: 23px;
    }

    .header {
        height: 81px;

        &__toolbar {
            height: 100%;
        }

        &__logo {}

        &__title {
            padding-left: 10px;
            text-decoration: none;
            font-weight: bold;
            font-size: 20px;
        }

        &__space {
            flex-grow: 1;
        }
    }

    .sidebar {
        &__list {}

        &__item {
            &-selected {
                .sidebar__title span {
                    font-weight: bold;
                }
            }
        }

        &__title {}
    }
}