@import "./variables";

.w-100 {
    width: 100%;
}

@for $i from 0 through 12 {
    .p-#{$i} {
        padding: #{$spacing * $i}px;
    }

    .pt-#{$i} {
        padding-top: #{$spacing * $i}px;
    }

    .mt-#{$i} {
        margin-top: #{$spacing * $i}px;
    }

    .mb-#{$i} {
        margin-bottom: #{$spacing * $i}px;
    }

    .mx-#{$i} {
        margin-left: #{$spacing * $i}px;
        margin-right: #{$spacing * $i}px;
    }
}
