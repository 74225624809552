.GridTableCellComponent {
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      background: #000;
      max-width: 70px;
      max-height: 70px;
    }
  }
}
