.raw-data-item {
  padding: 20px;
  border-bottom: 1px solid grey;

  &:last-child {
    border: none;
  }
}

.row-select {
  .MuiSelect-select {
    width: 100%;
    min-width: 180px;
  }
}

.row {
  display: flex;
  margin-bottom: 20px;
}

.date-picker {
  min-width: 200px !important;
  max-width: 300px !important;
}

.select {
  min-width: 250px;
  max-width: 300px !important;
}
