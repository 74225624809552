.rate-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;

    &__title {
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0;
        color: #FFFFFF;
    }

    &__value {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        color: #FFFFFF;
    }
}